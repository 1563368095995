.customer-form {
    padding: 20px;
    background-color: #f6f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: #c5c7c5;
}

.customer-button {
    margin-left: 10px;
}

.confirm-buttons {
    margin-top: 30px;
}

.confirm-buttons-yes {
    margin-left: 10px;
}

.confirm-dialog {
    padding: 50px;
    background-color: #e4e4e4;
    border-radius: 10px;
}

#remove-customer-button {
    padding: 0px;
}

.site-group {
    margin-top: 10px;
    background-color: #f6f8fa;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #c5c7c5;
}

.site-group-title {
    margin-left: 10px;
}

.site-invoice-interval {
    margin-left: 10px;
}

.site-row {
    padding: 10px;
    margin-left: 10px;
}

.selected {
    background-color: #ccc;
}

.add-site-button {
    margin: 10px;
}

.add-site-row > div {
    padding: 0px 10px 0px 10px;
}

ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.site-group-list {
    margin-bottom: 40px;
}

.group-site-row {
    margin-left: 20px;
    padding: 2px;
}

.site-group-button-name {
    margin-top: 20px;
}

.group-edit-row {
    padding-bottom: 5px;
}

.site-group-table {
    padding: 0px 20px 0px 20px;
}

.input-feedback {
    color: red;
}

.customer-view {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0px;
    padding-bottom: 20px;
}

.removed {
    background-color: LightPink;
}

.customers-list {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0px;
    padding-bottom: 60px;
}

#customer-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #eaeaea;
    padding-left: 40px;
    padding-right: 40px;
}

.add-new-customer-button {
    margin-top: 10px;
    float: right;
    width: 100px;
    height: 40px;
}

.loading-sites-message {
    font-weight: bold;
    text-align: center;
}
