.ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.modal-title {
    padding: 0px 0px 20px 0px;
}

.button-row {
    margin-top: auto;
    padding-top: 20px;
}

.modal-content-list {
    flex-shrink: 1;
    overflow: scroll;
}

.add-site-button-dialog {
    margin-left: 10px;
    float: right;
    width: 80px;
}

.cancel-button-dialog {
    float: right;
    width: 80px;
}
