.accept-invoice-button {
    display: inline-block;
    height: 22px;
    padding: 0px 10px 0px 10px;
}

.product-row-amount {
    font-weight: bold;
}

.invoice-list {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.invoice-view {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.path-view {
    height: 60px;
}

.invoice-customer-info {
    min-height: 220px;
    background-color: #f6f8fa;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 20px;
}

.invoice-customer-title-row {
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c4c5c7;
}

.invoice-customer-title {
    color: #9b9b9b;
}

.invoice-customer-name {
    font-weight: bold;
    font-size: 18px;
}

.invoice-customer-column {
    margin-top: 20px;
}

.invoice-site-info {
    margin-top: 10px;
    padding-left: 60px;
    padding-right: 60px;
}

.invoice-site-info td {
    border-top: none !important;
}

.row-of-site {
    border-top: none !important;
}

.last-row-of-site {
    border-bottom: 3px solid #444444;
}

.first-row-of-site {
    border-top: 3px solid #444444;
}

.row-without-borders {
    border-top: 4px solid rgb(32, 5, 5) !important;
}

.invoice-site-column {
    background-color: white;
    border-right: 2px solid #cbcbcb;
    border-bottom: none;
}

.invoice-site-info-title {
    font-weight: bold;
}

.invoice-footer {
    height: 120px;
    background-color: #f6f8fa;
    padding-left: 60px;
    padding-right: 60px;
}

.accept-one-invoice-button {
    float: right;
}

.invoice-button {
    margin-top: 40px;
    width: 120px;
    height: 40px;
}

.invoice-remove-container {
    padding-left: 60px;
    padding-top: 10px;
}

.invoice-confirm-remove-button {
    margin-left: 10px;
}

.form-group input {
    margin-right: 10px;
    margin-left: 0px !important;
}

.DayPickerInput-Overlay {
    z-index: 4;
}
