.site-info {
    padding: 5px;
    background-color: #f6f8fa;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #c5c7c5;
}

.removed {
    background-color: LightPink;
}

.modal-title {
    padding: 0px 0px 20px 0px;
}

.modal-button-row {
    margin-top: 20px;
}

.add-product-button-dialog {
    margin-left: 10px;
}

.add-product-row > div {
    padding: 0px 10px 0px 10px;
}

.product-button-row {
    margin-top: 20px;
}

.save-site-button {
    margin-left: 10px;
}

.product-row-button {
    display: inline-block;
    height: 22px;
    padding: 0px 10px 0px 10px;
}

tr.selected td {
    background-color: #ccc;
    color: #fff;
}

#remove-product-button {
    padding: 0px;
}

.select-license-button {
    margin-top: 10px;
}

.site-info-row {
    padding-top: 2px;
    padding-bottom: 2px;
}

.without-customers-label {
    font-weight: normal;
    margin-left: 5px;
}

.sites-list {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0px;
    padding-bottom: 20px;
}

.site-info-device-count-alarm {
    margin-left: 10px;
    color: red;
}

.site-info-currency {
    margin-left: 7px;
}
